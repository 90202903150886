
import React, { ChangeEvent, ChangeEventHandler, useContext, useEffect, useRef, useState } from 'react'



import './CardRow.scss';

interface IProps {
    children?: React.ReactNode;
    offset:number;
    index:number;
    registerRowHeight(index:number,height:number):void;
    scrollUpper:number;
    scrollLower:number;
}

const CardRow = (props: IProps) => {
    const windowSize = useWindowSize();
    const rowRef = useRef<null | HTMLDivElement>(null)
    const [showRow, setShowRow] = React.useState<boolean>(false);

    useEffect(() => {
       // console.log() // Hello World
        props.registerRowHeight(props.index, rowRef.current?.clientHeight || 0)
      }, [rowRef.current?.clientHeight,  windowSize.width])

      

      React.useEffect(() => {
      
        //if(!imageVisible) {
          let topPosition = rowRef.current?.getBoundingClientRect().top;
          //console.log('top position: ' + topPosition + " scrollUpper: " + props.scrollUpper);
          if (topPosition != undefined && (props.scrollLower + 100) >= topPosition && (topPosition + (rowRef.current?.clientHeight || 0)) > -100 ) {
            setShowRow(true);
          } else {
            setShowRow(false);
          }
          
        //}
      }, [props.scrollUpper, props.scrollLower]);
        





    return (
        <div ref={rowRef} className={'card-row fade-in' + (showRow ? 'show' : 'hide')} style={{transform : `translateY(${props.offset}px)`}}>
            {props.children}
        </div>
    )
}

export default CardRow;


// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: 0 ,
      height: 0,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        if (window && window.innerWidth && window.innerHeight) {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
              });
        }
        
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }