import axios from "axios";
import Discovery from "../interfaces/Discovery";
import Page from "../interfaces/Page";
import User from "../interfaces/User";
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVICE_BASEPATH}`,
  headers: {
    "Content-type": "application/json",
  },
});

const findByClaimCode = async (claimCode: string) => {
  const response = await apiClient.get<Discovery>(`/discovery/claim/${claimCode}`);
  return response.data;
}

const claimDiscovery = async (claimCode : string, nameOrAddress : string) => {
  const response = await apiClient.post<Discovery>(`/discovery/claim/${claimCode}`, nameOrAddress);
  return response.data;
}

const validateUser = async (purpose : string, nameOrAddress : string) => {
  const response = await apiClient.post<User>(`/the-violet-spider/guest-book/sign`, nameOrAddress);
  return response.data;
}
const submitAnswer = async (userId:number, answer : string) => {
  const response = await apiClient.post<Discovery[]>(`/the-violet-spider/user/${userId}/question`, answer);
  return response.data;
}
const getGuestBook = async (pageNumber: number) => {
  const response = await apiClient.get<Page>(`/the-violet-spider/guest-book/page/${pageNumber}`);
  return response.data;
}

const DiscoveryService = {
    findByClaimCode,
    claimDiscovery,
    validateUser,
    getGuestBook,
    submitAnswer
}
export default DiscoveryService;