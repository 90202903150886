
import React, { ChangeEvent, ChangeEventHandler, useContext, useEffect } from 'react'


import ChoosenCard from '../../../interfaces/ChoosenCard';


import './TarotCard.scss';

interface IProps {
    cardBack:string;
    chosenCard?:ChoosenCard;
    onClick(selectCard:number):void;
    position:number;
    done:boolean;
    onCleanUp():void;
}

const TarotCard = (props: IProps) => {

    const [revealed, setRevealed] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [dealt, setDealt] = React.useState<boolean>(false);
    
    React.useEffect(() => {
        
    }, []);

    React.useEffect(() => {
        if(loaded) {
            setRevealed(true);
        }
    }, [loaded]);

    function onClick() {
        if(!props.chosenCard) {
            props.onClick(props.position);
        }
        
    }
        





    return (
      <>
        {!dealt && <div onAnimationEnd={() => setDealt(true)} className={'deal-wrapper card-' + props.position}><img className='card-back' width={296} src={`${props.cardBack}/tokenhalf`}  /></div>}
        {dealt && !props.done &&  <div className={'tarot-card ' + (revealed ? 'revealed' : '')} style={{transitionDelay : (props.position * 0.25) + 's'}}>
            <div className='card-back-wrapper' onClick={onClick}>
            <img className='card-back' width={296}  src={`${props.cardBack}/tokenhalf`} />
            </div>
            {props.chosenCard && <img className='card-front' onLoad={() => setLoaded(true)} width={296} src={`${props.chosenCard.imageUrl}`} />}
        </div>}
        {props.done && <div onAnimationEnd={() => props.onCleanUp()} className={'tarot-card done'} style={{animationDelay : (props.position * 0.1) + 's'}}>
            {props.chosenCard && <img className='card-front' onLoad={() => setLoaded(true)} width={296} src={`${props.chosenCard.imageUrl}`} />}
        </div>}
        </>
    )
}

export default TarotCard;