import React, { Fragment } from 'react';
import logo from './logo.svg';
import './App.scss';
import DiscoveryService from './service/DiscoveryService';
import Page from './interfaces/Page';
import GuestBook from './interfaces/GuestBook';
import User from './interfaces/User';
import TheCards from './components/TheCards/TheCards';
import AudioControl from './components/AudioControl';
import axios from 'axios';
import Discovery from './interfaces/Discovery';

function App() {

    const [formInput, setFormInput] = React.useState<string>('');
    const [muted, setMuted] = React.useState<boolean>(false);
    const [guestBook, setGuestBook] = React.useState<Page[]>([]);
    const [user, setUser] = React.useState<User>();
    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [userDiscoveries, setUserDiscoveries] = React.useState<Discovery[]>([]);
    const [discoveryFetched, setDiscoveryFetched] = React.useState<boolean>(false);
    const [answer, setAnswer] = React.useState<string>('');
    const [answerLoading, setAnswerLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (user) {
        getDiscoveries(user.id);
      }
    }, [user]);

    function getDiscoveries(userId:number) {
      
        let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/discovery/user/${userId}`;
        
        axios({
          "method": "GET",
          "url" : url
        })
        .then((response) => {
          let discoveries:Discovery[] = response.data;
          setUserDiscoveries([... discoveries]);
          setDiscoveryFetched(true);
        })
        .catch((error) => {
          console.log(error)
        })
        
    }

    function submitAnswer(event:React.FormEvent<HTMLFormElement>) {
      event.preventDefault();
      if(!answerLoading) {

      
      setAnswerLoading(true);
      DiscoveryService.submitAnswer(user!.id, answer).then((claimResponse) => {
        setAnswerLoading(false);
        setAnswer('');
        setUserDiscoveries([... claimResponse]);
      })
      .catch((error) => {
        setAnswerLoading(false);
          if( error.response ){
            //setFormError(error.response.data);
          } else {
            console.log(error);
          }
          //context.setShowLoader?.(`Claiming Discovery`, false);
      });
      }
    }

    function claimDiscovery(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if(!answerLoading) {
    setAnswerLoading(true);
    let submission = formInput;
    setFormInput('');

    
      DiscoveryService.validateUser('the-violet-spider', submission).then((claimResponse) => {
        setAnswerLoading(false);
          setUser(claimResponse);
          getGuestBook(0);
          //setClaimMessage("You have claimed this discovery!");
          //context.setShowLoader?.(`Claiming Discovery`, false);
      })
      .catch((error) => {
        setAnswerLoading(false);
          if( error.response ){
            //setFormError(error.response.data);
          } else {
            console.log(error);
          }
          //context.setShowLoader?.(`Claiming Discovery`, false);
      });
    }
    }
  
    function getGuestBook(page:number) {
      DiscoveryService.getGuestBook(page).then((pageResponse) => {
        console.log(pageResponse);
        let updatedGuestBook = [... guestBook];
        if (page === 0) {
          updatedGuestBook = [];
        }
        updatedGuestBook.push(pageResponse);
        setGuestBook(updatedGuestBook);
        //setClaimMessage("You have claimed this discovery!");
        //context.setShowLoader?.(`Claiming Discovery`, false);
    })
    .catch((error) => {
        if( error.response ){
          //setFormError(error.response.data);
        } else {
          console.log(error);
        }
        //context.setShowLoader?.(`Claiming Discovery`, false);
    });
    }

    React.useEffect(() => {
      getGuestBook(currentPage)

  
  
   
  }, [currentPage])


  function logout() {
    console.log('clicked');
    setUser(undefined);
  }
  return (
    <div className="app">
      {!user && <div className="app fade-in-ani">
      <header>
      
      <img src="/the-violet-spider.png" />
      <div className="overlay">
        <h1>The Violet Spider</h1>
        <h2>TATTOO APOTHECARY TAROT</h2>
      </div>

      </header>
      <div className='page-body'>
 
      <div className='guest-list'>
        <h2>Guest Book</h2>
        <div className={'form-submit ' + (answerLoading ? 'loading' : '')}>
        <form onSubmit={claimDiscovery}>
          <input maxLength={128} type='text' autoFocus={true} onChange={(e) => setFormInput(e.target.value)} value={formInput} />
          <button type='submit'>submit</button>
        </form>
      </div>
        <ul>
          {guestBook.map((page:Page) => 
            <Fragment key={page.number}>
              {page.content.map((entry:GuestBook) => 
                <li key={entry.id}><div className='guest-card'><span className='name'>{entry.name}</span> <span className='date'>{new Date(entry.dateSigned).toLocaleDateString()}</span></div></li>
              )}
            </Fragment>
          )}
          
        </ul>
        </div>
      </div>
      </div>}
      {user && discoveryFetched && userDiscoveries.filter((discovery:Discovery) => discovery.actionAlias === 'the-trial-of-vision').length === 0 && <div className='fade-in-ani'>
                <div className={'form-submit drive '  + (answerLoading ? 'loading' : '')}><h2>What Drives You?</h2>
                
                <form onSubmit={submitAnswer}>
                  <input onChange={(e) => setAnswer(e.target.value)} value={answer} />
                  <button type='submit'>submit</button>
                  </form>
                </div>
        </div>
      }
      {user && discoveryFetched && userDiscoveries.filter((discovery:Discovery) => discovery.actionAlias === 'the-trial-of-vision').length >= 1 && <div className='fade-in-ani'>
        <AudioControl setMuted={setMuted}/>
        <TheCards getDiscoveries={getDiscoveries} discoveries={userDiscoveries} muted={muted} logout={logout} user={user} />
        </div>
      }
    </div>
  );
}

export default App;
