import { cleanup } from '@testing-library/react';
import axios from 'axios';
import { userInfo } from 'os';
import React, { ChangeEvent, ChangeEventHandler, useContext, useEffect } from 'react'
import { start } from 'repl';
import useSound from 'use-sound';
import './TurnCounter.scss';


interface IProps {
    turn:number;
    cleanUp:boolean;
}

const TurnCounter = (props: IProps) => {

    const [turnList, setTurnList] = React.useState<number[]>([]);

    React.useEffect(() => {
        if (props.turn === 0) {
            setTurnList([]);
        } else {
            let lastItem = 0;
            if (turnList && turnList.length > 0) {
                lastItem = turnList[turnList.length - 1];

            }
            if (props.turn > lastItem) {
                let updatedTurnList = turnList;
                updatedTurnList.push(props.turn);
                setTurnList([... updatedTurnList]);
            }

        }
    }, [props.turn]);





    return (
        <div className='turn-counter'>
            {turnList.map((turn:number, index:number) => 
                <>
                    {(Math.abs(index - (turnList.length -1)) * 33) < 150 && <div key={turn} className='turn-stick' style={{transitionDelay : `${props.cleanUp ? index * 0.1 : 0}s`, transform : `rotateZ(${props.cleanUp ? -180 : Math.abs(index - (turnList.length -1)) * -33}deg)`}}><div className='paddle'><span>{turn}</span></div></div>}
                </> 
            )}
        </div>
    )
}

export default TurnCounter;