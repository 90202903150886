import { cleanup } from '@testing-library/react';
import axios from 'axios';
import { userInfo } from 'os';
import React, { ChangeEvent, ChangeEventHandler, useContext, useEffect, useRef, useState } from 'react'
import { start } from 'repl';
import CardResponse from '../../interfaces/CardResponse';
import ChoosenCard from '../../interfaces/ChoosenCard';
import User from '../../interfaces/User';
import CardRow from './CardRow/CardRow';
import TarotCard from './TarotCard/TarotCard';
import useSound from 'use-sound';
import './TheCards.scss';

import chime1Sound from '../../audio/chime1.mp3';
import chime2Sound from '../../audio/chime2.mp3';
import chime3Sound from '../../audio/chime3.mp3';
import chime4Sound from '../../audio/chime4.mp3';
import chime5Sound from '../../audio/chime5.mp3';
import chime6Sound from '../../audio/chime6.mp3';
import chime7Sound from '../../audio/chime7.mp3';
import TurnCounter from './TurnCounter';
import Discovery from '../../interfaces/Discovery';
interface IProps {
    user:User;
    logout():void;
    muted:boolean;
    getDiscoveries(userId:number):void;
    discoveries:Discovery[];
}

const TheCards = (props: IProps) => {
    const muted = props.muted;
    const [scrollPosition, setScrollPosition] = React.useState<number>(0);
    
    const gameWrapperRef = useRef<null | HTMLDivElement>(null)
    const [cleanUp, setCleanUp] = React.useState<boolean>(false);
    const [game, setGame] = React.useState<CardResponse[]>([]);
    const [chosenCard, setChosenCard] = React.useState<ChoosenCard>();
    const [gameOver, _setGameOver] = React.useState<boolean>(false);
    const [offset, setOffset] = React.useState<number>(1);
    const [rowHeights, setRowHeights] = React.useState<number[]>([]);
    const [cleanCount, setCleanCount] = React.useState<number>(0);
    const [gameHeight, setGameHeight] = React.useState<number>(0);
    const scrollLowerBounds = scrollPosition + gameHeight;
    const gameOverRef = React.useRef(gameOver);
    const [turn, setTurn] = React.useState<number>(0);
    const [playbackRate, setPlaybackRate] = React.useState(1);
    const [cardWait, setCardWait] = React.useState<boolean>(false);
    const [chime1] = useSound(
        chime1Sound,
        { volume: 0.1 }
      );

      const [chime2] = useSound(
        chime2Sound,
        { volume: 0.1}
      );
      const [chime3] = useSound(
        chime3Sound,
        { volume: 0.1 }
      );
      const [chime4] = useSound(
        chime4Sound,
        { volume: 0.1 }
      );
      const [chime5] = useSound(
        chime5Sound,
        { volume: 0.25 }
      );
      const [chime6] = useSound(
        chime6Sound,
        { volume: 0.25, playbackRate: playbackRate }
      );
      const [chime7] = useSound(
        chime7Sound,
        { volume: 0.25 }
      );
      function playRandomChimeSound() {
        if (!muted) {
            
          let sound = getRandomInt(4);
          if(sound === 0) {
            chime1();
          } else if (sound === 1) {
            chime2();
          } else if (sound === 2) {
            chime3();
          } else if (sound === 3) {
            chime4();
          }
        
        }
      }
      function playRandomGongSound() {
        if (!muted) {
        setPlaybackRate((getRandomInt(10) / 10) + .7);
        //setPlaybackRate(1.5);
        let sound = getRandomInt(3);
        if(sound === 0) {
          chime6();
        } else if (sound === 1) {
          chime6();
        } else if (sound === 2) {
          chime6();
        } 
        }
    }
 
    
      function getRandomInt(max:number) {
        return Math.floor(Math.random() * max);
      }
    

    function setGameOver(gameOver:boolean) {
        gameOverRef.current = gameOver;
        _setGameOver(gameOver);
    }

    React.useEffect(() => {
        startGame();
    }, []);

    useEffect(() => {
      setGameHeight(gameWrapperRef.current?.clientHeight || 0);
       
     }, [gameWrapperRef.current?.clientHeight])

    React.useEffect(() => {
        if (game.length > 0) {
            let cardCount = 0;
            game.forEach((game:CardResponse) => {
                cardCount = cardCount + game.hand.length;
            });
            if (cardCount === cleanCount) {
                startGame();
            }
        }
    }, [cleanCount]);

    function getTotalRowHeights():number {
        return rowHeights.reduce(function(a, b) { return a + b; }, 0);
    }

    function startGame() {
      setCardWait(false);
        setTurn(0);
        setCleanCount(0);
        setCleanUp(false);
        setGameOver(false);
        setRowHeights([]);
        setOffset(1);
        setChosenCard(undefined);
        setGame([]);
        axios({
            "method": "POST",
            "url": `${process.env.REACT_APP_CARD_BASEPATH}/game/`,
            "headers": {
                'content-type': 'application/json'
            },
            "data": {playerId : props.user.id}

        })
            .then((response) => {
                console.log(response.data);
                let updatedTurn:CardResponse = response.data;
                setChosenCard(updatedTurn.chosenCard);
                setGame([updatedTurn]);
                
            })
            .catch((error) => {
                console.log(error);
                
            })
    }

    function selectCard(index:number) {
      if(!cardWait) {
        let gameId = game[0].gameId;
        setCardWait(true);
        axios({
            "method": "POST",
            "url": `${process.env.REACT_APP_CARD_BASEPATH}/game/${gameId}`,
            "headers": {
                'content-type': 'application/json'
            },
            "data": {index : index + 1}

        })
            .then((response) => {
                let updatedTurn:CardResponse = response.data;
                let updatedGame = game;
                for (let i = 0; i < game.length; i++) {
                    if((updatedTurn.correct && updatedGame[i].turn === updatedTurn.turn - 1) ||  (!updatedTurn.correct && updatedGame[i].turn === updatedTurn.turn)) {
                        updatedGame[i].hand = updatedTurn.hand;
                        updatedGame[i].correct = updatedTurn.correct;
                    }
                }
                if(updatedTurn.correct) {
                    if(turn + 1 === 5) {
                      setTimeout(() => {
                        props.getDiscoveries(props.user.id);
                      }, 5000);
                    }
                    playRandomChimeSound();
                    setTurn(turn + 1);
                    let nextTurn = {} as CardResponse;
                    nextTurn.cardBacks = updatedTurn.cardBacks;
                    nextTurn.turn = updatedTurn.turn;
                    nextTurn.gameId = updatedTurn.gameId;
                    nextTurn.chosenCard = updatedTurn.chosenCard;
                    updatedGame.push(nextTurn);
                    setOffset(offset + 1);
                }
                if(!updatedTurn.correct) {
                    playRandomGongSound();
                    setGameOver(true);
                }

                setGame([... updatedGame]);
                setCardWait(false);
               // setGame(response.data);
                
            })
            .catch((error) => {
                console.log(error);
                setCardWait(false);
                
            })
          }
    }
        

    function getOffset(index:number):number {
        
        let previousRowHeightTotal = 0;
        for(let i = 0; i < rowHeights.length; i++) {
            if(i > index) {
                previousRowHeightTotal = previousRowHeightTotal + rowHeights[i];   
            }
             
        }
        return previousRowHeightTotal;
        // if(offset === turn) {
        //     return 0;
        // } else {
        //     return (   * 250);
        // }
    }

    function registerRowHeight(index:number, height: number) {
        let updatedRowHeights = rowHeights;
        updatedRowHeights[index] = height;

        setRowHeights([... updatedRowHeights]);
    }

    function onCleanUp() {
        setCleanCount(cleanCount + 1);
    }

    useEffect(() => {
        const handleEsc = (event:KeyboardEvent) => {
        event.preventDefault();
           if (event.key === ' ' && gameOverRef.current === true) {
               setCleanUp(true);
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
          window.removeEventListener('keydown', handleEsc);
        };
      }, []);

    return (
        <>
        <div className='wrapper-wrapper'></div>
        <div ref={gameWrapperRef} onScroll={() => setScrollPosition(gameWrapperRef.current?.scrollTop || 0)} className={'game-wrapper ' + (gameOver ? 'show-controls' : '')}>
        <div className={'the-cards'} style={{height : getTotalRowHeights()}}>
            
            {game.length > 0 && game.map((turn:CardResponse, index:number) => 
            
             <CardRow scrollUpper={scrollPosition} scrollLower={scrollLowerBounds} registerRowHeight={registerRowHeight} index={index + 1} offset={getOffset(index + 1)} key={turn.gameId + '-' + turn.turn}>
                
                <div className='card-list'>
                    <ul>
                    {turn.cardBacks && turn.cardBacks.length > 0 && turn.cardBacks.map((cardBack:string, index:number) =>
                        <li key={index}><TarotCard onCleanUp={onCleanUp} done={cleanUp} cardBack={cardBack} chosenCard={turn.hand ? turn.hand[index] : undefined} onClick={selectCard} position={index} /></li>
                    )}
                    
                    </ul>     
                </div>  
            </CardRow>)}
            {game.length > 0 && 
            <CardRow scrollUpper={scrollPosition} scrollLower={scrollLowerBounds} registerRowHeight={registerRowHeight} index={0} offset={getOffset(0)}>
            
                <div className='card-list'>
                    <ul>
                        <li key={game[0].gameId}><TarotCard onCleanUp={onCleanUp} done={cleanUp} cardBack={'https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/811526d6-4ca9-4f52-2ecf-31ec093f9d00/tokenhalf'} chosenCard={chosenCard} onClick={selectCard} position={0} /></li>
                    </ul>
                </div>
                </CardRow>
            }
        </div>
        {turn > 0 && <div className='game-status fadeIn'><TurnCounter cleanUp={cleanUp} turn={turn} /></div>}
        <div className='game-controls'><button onClick={() => setCleanUp(true)}>
        <svg style={{width: 24, height: 24}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
</svg>
          </button></div>
          <div className='game-message'>
            <h2>{props.user.name} <button onClick={() => props.logout()}><svg style={{width: 24, height: 24}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
</svg></button></h2>
            {props.discoveries.filter((discovery:Discovery) => discovery.actionAlias === 'the-trial-of-vision-complete').length === 0 && <p>Use your powers of vision to predict the chosen card 5 times.</p>}</div>
        </div>
        
        </>
    )
}

export default TheCards;


// const useScrollPosition = () => {
//     const [scrollPosition, setScrollPosition] = useState(0);
  
//     useEffect(() => {
//       const updatePosition = () => {
//         setScrollPosition(window.pageYOffset);
//       }
//       window.addEventListener("scroll", updatePosition);
//       updatePosition();
//       return () => window.removeEventListener("scroll", updatePosition);
//     }, []);
  
//     return scrollPosition;
//   };